const rootURL = process.env.REACT_APP_API_URL

export async function apiRequest(token, method, path, body) {
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  if (method.toLowerCase() !== 'get') {
    headers['Content-Type'] = 'application/json'
  }
  const response = await fetch(`${rootURL}${path}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  if (response.status === 204) {
    return
  }
  let data = await response.json()
  return data
}
