import React from 'react'
import Container from '@material-ui/core/Container'
import styled from '@material-ui/styles/styled'
import { Spinner } from 'components/atoms/spinner/Spinner'

const StyledContainer = styled(Container)({
  marginTop: '200px',
})

export function Loading() {
  return (
    <StyledContainer maxWidth="xs">
      <Spinner />
    </StyledContainer>
  )
}
