import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import AppBar from '@material-ui/core/AppBar'
import LinearProgress from '@material-ui/core/LinearProgress'
import { OpenRouteTopbar } from 'components/organisms/open-route-topbar/OpenRouteTopbar'
import { PrivateRouteTopbar } from 'components/organisms/private-route-topbar/PrivateRouteTopbar'
import { useIsFetching } from 'react-query'
import { HideOnScroll } from './components/hide-on-scroll/HideOnScroll'
import { Toolbar } from './components/toolbar/Toolbar'

export function Header(props) {
  const isFetching = useIsFetching()
  const { isAuthenticated } = useAuth0()

  return (
    <HideOnScroll {...props}>
      <AppBar position="sticky" color="inherit" elevation={0}>
        <Toolbar>
          {isAuthenticated ? <PrivateRouteTopbar /> : <OpenRouteTopbar />}
        </Toolbar>
        {!!isFetching && <LinearProgress />}
      </AppBar>
    </HideOnScroll>
  )
}
