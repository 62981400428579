import React from 'react'
import { CaptionContainer } from './components/caption-container/CaptionContainer'
import { Caption } from './components/caption/Caption'
import { Divider } from './components/divider/Divider'
import { ExternalLink } from './components/external-link/ExternalLink'

export function Footer() {
  return (
    <>
      <Divider />
      <CaptionContainer>
        <Caption>
          WHU - Otto Beisheim School of Management, Burgplatz 2, 56179 Vallendar
        </Caption>
        <ExternalLink
          href="https://www.whu.edu/en/footernavigation/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Statement
        </ExternalLink>
        <ExternalLink
          href="https://www.whu.edu/en/footernavigation/misc/privacy-policy-students"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Statement Students
        </ExternalLink>
        <ExternalLink
          href="https://www.whu.edu/footernavigation/imprint/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Imprint
        </ExternalLink>
      </CaptionContainer>
    </>
  )
}
