import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import helveticaNeueW01 from 'assets/3a46542f-f429-4821-9a88-62e3be54a640.woff2'

const helvetica = {
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${helveticaNeueW01}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009fe3',
      dark: '#2c4592',
    },
    grey: {
      900: '#212529',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['Helvetica Neue', 'sans-serif'].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [helvetica],
      },
    },
    MuiButton: {
      outlined: {
        borderRadius: 0,
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: '#009fe2',
          color: '#ffffff',
        },
        borderColor: '#009fe2',
      },
      outlinedSizeSmall: {
        fontSize: '15px',
        fontWeight: '900',
      },
    },
    MuiDrawer: {
      paper: {
        width: '450px',
        backgroundColor: '#515255',
        color: '#ffffff',
      },
    },
  },
})
