import { apiRequest } from './base'

const createApplication = async ({
  token,
  termId,
  programId,
  opptyRecordType,
}) => {
  const body = { termId, programId, opptyRecordType }

  return apiRequest(token, 'post', '/applications', body)
}

const fetchApplications = async (key, user, token) => {
  return apiRequest(token, 'get', '/applications')
}

const fetchApplication = async (key, id, token, user) => {
  const rawApp = await apiRequest(token, 'get', '/applications/' + id)
  return prepareForForm(rawApp)
}

const saveApplication = async ({ id, token, data, submit }) => {
  const body = {
    ...prepareForAPI(data),
    submit,
  }
  return apiRequest(token, 'PATCH', '/applications/' + id, body)
}

const floatFields = [
  'amountEmployerFinancialSponsorship',
  'annualBaseSalary',
  'currentEctsCredits',
  'mostRecentBonus',
  'numberOfDirectReports',
  'numberOfSemesters',
  'practiceSemesters',
  'semestersOff',
  'semestersStudienkolleg',
  'totalWorkExperience',
  'yearsOfManagerialExperience',
  'monthManagerialExperience',
  'monthPostGraduateWorkExperience',
]

const booleanFields = [
  'apprenticeship',
  'bscInternshipsCompleted',
  'currentlyEnrolled',
  'englishSpeakingSchoolGraduate',
  'exmatriculated',
  'experienceAbroad',
  'managementExperience',
  'mifDoubleDegree',
  'nativeSpeaker',
  'noGmat',
  'noProfessionalExperience',
  'noTest',
  'studyInGermany',
  'whuBachelor',
]

const dateFields = [
  'gmatDate',
  'dateOfCompletion',
  'bscGraduationYear',
  'englishTestDate',
]

function prepareForAPI(data) {
  floatFields.forEach(field => {
    if (field in data) {
      data[field] = data[field] ? parseFloat(data[field]) : null
    }
  })
  booleanFields.forEach(field => {
    if (field in data) {
      data[field] = Boolean(data[field])
    }
  })
  dateFields.forEach(field => {
    if (field in data && data[field]) {
      // if it's ISODate
      if (data[field] instanceof Date) {
        data[field] = data[field].toISOString()
      }
      if (data[field].includes('T')) {
        data[field] = data[field].split('T')[0]
      }
    }
  })
  return {
    ...data,
    // should not send these
    lastSaved: undefined,
    lastDocumentUpload: undefined,
    id: undefined,
    term: undefined,
    program: undefined,
    status: undefined,
  }
}

function prepareForForm(app) {
  const result = {}
  // set nulls to empty string so React(Formik) wont complain
  Object.keys(app).forEach(field => {
    if (app[field] === null && !dateFields.includes(field)) {
      result[field] = ''
    } else {
      result[field] = app[field]
    }
  })
  return result
}

export {
  fetchApplications,
  createApplication,
  fetchApplication,
  saveApplication,
}
