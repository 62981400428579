import { apiRequest } from './base'

const rootURL = process.env.REACT_APP_API_URL

const createProfile = async ({ user, token }) => {
  const response = await fetch(`${rootURL}/profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response.ok) return response.json()

  throw new Error(response.statusText)
}

const getProfile = async (_, token) => {
  let data = await apiRequest(token, 'get', '/profile')
  if (data.lastName.includes('@')) {
    data.lastName = ''
  }
  return data
}

const updateProfile = async ({ token, data }) => {
  delete data.isFresh
  delete data.email
  const response = await fetch(`${rootURL}/profile`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.ok) {
    return response
  } else {
    return new Error(response.statusText)
  }
}

export { createProfile, getProfile, updateProfile }
