import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import styled from '@material-ui/styles/styled'
import HOME_IMAGE from 'assets/homeBanner.jpg'
import { ResponsiveImg } from 'components/atoms/responsive-img/ResponsiveImg'

const TemplateContainer = styled(Container)({ padding: 0, maxWidth: '100%' })

const BannerContainer = styled('div')({
  marginLeft: 'auto',
  marginRight: 'auto',
})

const ContentContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export function OpenRouteLayout(props) {
  const { children } = props

  return (
    <TemplateContainer>
      <BannerContainer>
        <ResponsiveImg src={HOME_IMAGE} alt="WHU Application Center Banner" />
      </BannerContainer>
      <ContentContainer>{children}</ContentContainer>
    </TemplateContainer>
  )
}

OpenRouteLayout.propTypes = {
  children: PropTypes.element.isRequired,
}
