import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const useToken = () => {
  const { user, getIdTokenClaims } = useAuth0()
  const [token, setToken] = React.useState('')
  React.useEffect(() => {
    const getToken = async () => {
      const token = (await getIdTokenClaims()).__raw
      setToken(token)
    }
    if (user) {
      getToken()
    }
  }, [getIdTokenClaims, user])
  return token
}

export default useToken
