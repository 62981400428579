import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/core/styles'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import CssBaseline from '@material-ui/core/CssBaseline'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { createBrowserHistory } from 'history'
import { theme } from './configs/theme'
import { ErrorFallback } from './components/molecules/error-fallback/ErrorFallback'

const queryCache = new QueryCache()
const history = createBrowserHistory()

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn:
      'https://d5b266bd5b8d4451ab28f1cb384882eb@o248005.ingest.sentry.io/5370594',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
  })
}

ReactDOM.render(
  <ReactQueryCacheProvider queryCache={queryCache}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
            <App />
          </Sentry.ErrorBoundary>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ThemeProvider>
    {/* <ReactQueryDevtools initialIsOpen /> */}
  </ReactQueryCacheProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
