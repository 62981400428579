export function getColorPrimaryDark(theme) {
  return theme.palette.primary.dark
}

export function getColorGreyDark(theme) {
  return theme.palette.grey['900']
}

export function getColorSuccess(theme) {
  return theme.palette.success.main
}
