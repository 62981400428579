import React from 'react'

export function ErrorFallback() {
  return (
    <div>
      Something went wrong. If you keep seeing this error please contact us at{' '}
      <a href="mailto:oac@whu.edu">oac@whu.edu</a>
    </div>
  )
}
