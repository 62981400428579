import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import styled from '@material-ui/styles/styled'
import withStyles from '@material-ui/styles/withStyles'
import * as Sentry from '@sentry/react'
import { fetchApplications } from 'api/application'
import { getProfile } from 'api/contact'
import { SidebarDivider } from 'components/atoms/sidebar-divider/SidebarDivider'
import useToken from 'hooks/useToken'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

// const TEST_APPLICATIONS = [
//   {
//     id: 'test-id',
//     program: { name: 'program name', slug: 'program-slug' },
//     term: { name: 'term name' },
//   },
// ]

const StyledSidebarDivider = styled(SidebarDivider)({
  marginTop: '2rem',
  marginBottom: '2rem',
})

const ApplicationsTitle = withStyles({ root: { marginLeft: '1rem' } })(
  ({ classes }) => (
    <div className={classes.root}>
      <Typography variant="body1">Your Applications:</Typography>
    </div>
  )
)

const ApplicationListItem = withStyles({
  root: {
    '&:hover': {
      color: '#009fe2',
    },
  },
})(props => {
  const { id, program, classes, children } = props

  return (
    <ListItem
      button
      component={Link}
      to={`/applications/${program.slug}/${id}`}
      classes={classes}
    >
      {children}
    </ListItem>
  )
})

const ApplicationListItemText = withStyles({
  primary: {
    fontSize: '24px',
    fontWeight: 'bold',
    '&:hover:after': {
      content: '" "',
    },
  },
  secondary: {
    color: '#ffffff',
    fontSize: '18px',
  },
})(props => {
  const { primaryText, secondaryText, classes } = props

  return (
    <ListItemText
      primary={primaryText}
      secondary={secondaryText}
      classes={classes}
    />
  )
})

function renderApplication(application) {
  return (
    <ApplicationListItem {...application} key={application.id}>
      <ApplicationListItemText
        primaryText={application.program.name}
        secondaryText={application.term.name}
      />
    </ApplicationListItem>
  )
}

export function Applications(props) {
  const { onMenuClose, user } = props

  const token = useToken()

  const idUser = data => {
    if (data) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.setUser({
          email: data.email,
          username: `${data.firstName} ${data.lastName}`,
        })
      }
    }
  }

  const { data: profile } = useQuery(['profile', token], getProfile, {
    enabled: token && user,
    onSuccess: data => idUser(data),
    refetchOnWindowFocus: false,
  })

  const { data, isLoading } = useQuery(
    ['applications', user?.sub, token],
    fetchApplications,
    {
      enabled: token && user && profile,
      initialData: [],
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading) return <div>Loading applications...</div>

  if (data && data.length > 0)
    return (
      <>
        <StyledSidebarDivider />
        <ApplicationsTitle />
        <div onClick={onMenuClose} onKeyDown={onMenuClose}>
          <List>{data.map(renderApplication)}</List>
        </div>
      </>
    )

  return null
}

Applications.propTypes = {
  onMenuClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    sub: PropTypes.any,
  }),
}
