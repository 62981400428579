import { useAuth0 } from '@auth0/auth0-react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState } from 'react'
import { TopbarTitle } from 'components/atoms/topbar-title/TopbarTitle'
import { Sidebar } from './components/sidebar/Sidebar'
import { SignOutBtn } from './components/sign-out-btn/SignOutBtn'

export function PrivateRouteTopbar() {
  const [menuOpen, setMenuOpen] = useState(false)

  const { logout, user } = useAuth0()

  const handleOpenMenu = () => setMenuOpen(true)
  const handleCloseMenu = () => setMenuOpen(false)
  const handleSignOut = () => {
    window.sessionStorage.clear()
    logout({ returnTo: window.location.origin })
  }

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={handleOpenMenu}
      >
        <MenuIcon />
      </IconButton>
      <TopbarTitle />
      <Sidebar
        user={user}
        menuOpen={menuOpen}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
      />
      <SignOutBtn onClick={handleSignOut} />
    </>
  )
}
