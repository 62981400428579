import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

const StyledTypography = withStyles({
  root: {
    flex: 1,
    margin: 0,
    marginLeft: '1rem',
    fontSize: '32px',
    lineHeight: '39px',
    fontWeight: 'bold',
  },
})(Typography)

export function TopbarTitle(props) {
  const { children = 'WHU Application Center' } = props

  return (
    <StyledTypography
      component="h2"
      variant="h5"
      color="inherit"
      align="center"
      noWrap
    >
      {children}
    </StyledTypography>
  )
}

TopbarTitle.propTypes = {
  children: PropTypes.string,
}
