import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/SwipeableDrawer'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Home from '@material-ui/icons/Home'
import styled from '@material-ui/styles/styled'
import withStyles from '@material-ui/styles/withStyles'
import { SidebarDivider } from 'components/atoms/sidebar-divider/SidebarDivider'
import { Link } from 'react-router-dom'
import { Applications } from './components/applications/Applications'
import { Logo } from './components/logo/Logo'

const StyledList = styled(List)({
  width: 450,
  marginRight: 0,
  color: '#ffffff',
})

const StyledListItem = withStyles({
  root: {
    '&:hover': {
      color: '#009fe2',
    },
  },
})(ListItem)

const StyledListItemText = withStyles({
  primary: {
    fontSize: '24px',
    fontWeight: 'bold',
    '&:hover:after': {
      content: '" "',
    },
  },
})(ListItemText)

const StyledListItemIcon = styled(ListItemIcon)({
  color: '#ffffff',
  fontSize: '24px',
})

export function Sidebar(props) {
  const { user, menuOpen, handleCloseMenu, handleOpenMenu } = props

  return (
    <Drawer
      anchor="left"
      open={menuOpen}
      variant="temporary"
      onClose={handleCloseMenu}
      onOpen={handleOpenMenu}
    >
      <div onClick={handleCloseMenu} onKeyDown={handleCloseMenu}>
        <StyledList>
          <Logo />
          <SidebarDivider />
          <StyledListItem button component={Link} to={'/home'}>
            <StyledListItemIcon>
              <Home />
            </StyledListItemIcon>
            <StyledListItemText primary="Home" />
          </StyledListItem>

          <StyledListItem button component={Link} to={'/profile'}>
            <StyledListItemIcon>
              <AccountCircleIcon />
            </StyledListItemIcon>
            <StyledListItemText primary="Profile" />
          </StyledListItem>
        </StyledList>
      </div>
      <Applications onMenuClose={handleCloseMenu} user={user} />
    </Drawer>
  )
}

Sidebar.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  user: Applications.propTypes.user,
}
