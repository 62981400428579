import Button from '@material-ui/core/Button'
import styled from '@material-ui/styles/styled'
import React from 'react'
import { getColorPrimaryDark } from 'utils/theme'

export const SignOutBtn = styled(props => (
  <Button {...props} variant="text" size="small">
    Sign out
  </Button>
))(({ theme }) => ({
  borderRadius: 0,
  color: getColorPrimaryDark(theme),
}))
