import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Loading } from 'components/molecules/loading/Loading'
import { OpenRouteLayout } from 'components/templates/open-route-layout/OpenRouteLayout'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { Route, Switch } from 'react-router-dom'
import { Footer } from './components/templates/footer/Footer'
import { Maintenance } from './components/molecules/maintenance/Maintenance'
import { Header } from './components/templates/header/Header'

const PRIVATE_ROUTES = [
  {
    path: '/home',
    component: React.lazy(() => import('./components/pages/home/Home')),
  },
  {
    path: '/applications/:program/start',
    component: React.lazy(() =>
      import('./components/pages/application-start/ApplicationStart')
    ),
  },
  {
    path: '/applications/:program/:id',
    component: React.lazy(() =>
      import('./components/Applications/ApplicationSwitch')
    ),
  },
  {
    path: '/profile',
    component: React.lazy(() => import('./components/pages/profile/Profile')),
  },
]

const OPEN_ROUTES = [
  {
    path: '/',
    component: React.lazy(() => import('./components/pages/login/Login')),
  },
  {
    path: '/logout',
    component: React.lazy(() => import('./components/pages/logout/Logout')),
  },
]

const WITH_AUTHENTICATION_REQUIRED_CONFIG = {
  // eslint-disable-next-line react/display-name
  onRedirecting: () => <Loading />,
}

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true'

export default function App() {
  useScrollToTop()

  if (isMaintenance) return <Maintenance />

  return (
    <React.Suspense fallback={<Loading />}>
      <Header />
      <Switch>
        {PRIVATE_ROUTES.map(({ path, component }) => {
          const Component = withAuthenticationRequired(
            component,
            WITH_AUTHENTICATION_REQUIRED_CONFIG
          )

          return (
            <Route
              exact
              key={path}
              path={path}
              render={props => <Component {...props} />}
            />
          )
        })}
        {OPEN_ROUTES.map(({ path, component: Component }) => (
          <Route
            exact
            key={path}
            path={path}
            render={props => (
              <OpenRouteLayout>
                <Component {...props} />
              </OpenRouteLayout>
            )}
          />
        ))}
      </Switch>
      <Footer />
    </React.Suspense>
  )
}
