import React from 'react'
import Container from '@material-ui/core/Container'
import ListItem from '@material-ui/core/ListItem'
import NavLogo from 'assets/WhuLogoWhite.svg'

export function Logo() {
  return (
    <ListItem>
      <Container>
        <img src={NavLogo} alt="Logo" height="60" />
      </Container>
    </ListItem>
  )
}
