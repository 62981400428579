import MuiToolbar from '@material-ui/core/Toolbar'
import styled from '@material-ui/styles/styled'
import { getColorPrimaryDark } from 'utils/theme'

export const Toolbar = styled(MuiToolbar)(({ theme }) => {
  const primaryColor = getColorPrimaryDark(theme)

  return {
    borderBottom: `1px solid ${primaryColor}`,
    color: primaryColor,
  }
})
