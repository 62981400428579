import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import LOGO from 'assets/WhuLogo.svg'
import { TopbarTitle } from 'components/atoms/topbar-title/TopbarTitle'

export function OpenRouteTopbar() {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Hidden xsDown>
        <Grid item xs>
          <img src={LOGO} alt="Logo" height="35" />
        </Grid>
      </Hidden>

      <Grid item xs={12} sm={6}>
        <TopbarTitle />
      </Grid>

      <Hidden xsDown>
        <Grid item xs />
      </Hidden>
    </Grid>
  )
}
